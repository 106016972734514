<template>
    <div
        class="custom-modal custom-subscription-modal"
    >
        <span
            class="close-btn kw kw-x"
            @click="close"
        />
        <h3 class="title">{{ $t('upgrade-your-custom-subscription') }}</h3>

        <p class="description" v-html="$t('custom-subscription-text', { cycle, count: currentCount, price: currentAmountDisplay })" />

        <div class="input-wrapper">
            <p>{{ $t('increase-number-of-keywords-to') }}</p>
            <kw-input-field
                input-id="increase-number"
                class="number-input"
                name="increaseKeywordsNumber"
                :value="count"
                isNumeric
                @input="onInput"
                @keypress="onKeypress"
                placeholder=""
                formatNumber
                :error-data="validationError"
            />
        </div>

        <div class="new-pricing">
            <p class="title">{{ $t('new-pricing') }}</p>
            <p>{{ $t('common.package') }}: <span class="new-package-desc" v-html="$t('custom-package-description', {count, cycle})" /></p>
            <p>{{ $t('invoice-amount') }}: <b>$ {{ newAmountDisplay }} {{ $t('per-year') }}</b></p>
        </div>

        <div class="submit-block">
            <kw-button 
                :button-text="$t('confirm-upgrade')"
                button-type="primary"
                @click="onSubmit"
                :disabled="isDisabled"
            />
            <p class="hint">
                {{ $t('custom-subscription-hint') }}
            </p>
        </div>
        <p class="downgrade-hint" v-html="$t('custom-subscription-downgrade-hint')" />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import KwNumericInputField from '@/components/ui-elements/KwNumericInputField'
    import {TYPING_DEBOUNCE} from '@/constants';

    export default {
        name: 'CustomSubscriptionModal',
        components: {
            'kw-numeric-input-field': KwNumericInputField
        },
        props: {
            currentPlan: {
                type: Object,
                required: true,
            },
        },
        data () {
            return {
                loading: false,
                count: '',
                validationError: {error: false, message: ''},
                debouncedHandler: _.debounce(fn => fn(), TYPING_DEBOUNCE),
                existingPackage: null
            };
        },
        computed:{
            ...mapGetters([
                'getPackages'
            ]),
            cycle(){
                return this.currentPlan?.subscription.cycle === 'hourly' ? 'bi-hourly' : this.currentPlan?.subscription.cycle;
            },
            currentCount(){
                return this.currentPlan?.subscription.package.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },
            currentAmount(){
                let cost = 0;
                if(this.currentPlan?.subscription.billing_cycle === "monthly") {
                    cost = Number(this.currentPlan.subscription.stripe_plan_amount.replace(/,/g, '')) / this.currentPlan.subscription.package;
                } else {
                    const monthlyCost = Number(this.currentPlan.subscription.stripe_plan_amount.replace(/,/g, '')) / 12;
                    cost = monthlyCost / this.currentPlan.subscription.package;
                }

                return cost;
            },
            currentAmountDisplay(){
                return Number.isInteger(this.currentAmount) ? this.currentAmount : this.currentAmount.toFixed(4);
            },
            // billingCycle() {
            //     return this.currentPlan?.subscription.billing_cycle !== 'monthly' ? 'yearly' : this.currentPlan.subscription.billing_cycle
            // },
            isDisabled(){
                return this.loading || this.validationError.error || !this.count;
            },
            newAmount(){
                return this.existingPackage ? this.existingPackage.annual_price * 12 : this.count * this.currentAmount * 12;
            },
            newAmountDisplay(){
                return Math.round(this.newAmount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        },
        mounted(){
            console.log(this.currentPlan)
        },
        methods: {
            ...mapActions([
                'requestToUpgradePlan',
            ]),
            onKeypress(e){
                if(this.count >= 10000000000){
                    e.preventDefault();
                }
            },
            close () {
                this.$router.replace({query: null});
                this.$emit('close');
            },
            onSubmit(){
                this.loading = true;
                this.requestToUpgradePlan(+this.count).then(()=>{
                    this.close();
                }).finally(()=>{
                    this.loading = false;
                })
            },
            onInput(value) {
                this.count = value;
                this.debouncedHandler(()=>{
                    if(this.getPackages){
                        this.existingPackage = this.getPackages.find((item) => {
                            return item.keywords_tracked === +value && item.cycle === this.currentPlan?.subscription.cycle;
                        });
                    }
                })
                this.validateInput(value);
            },
            validateInput(value){
                if(value <= this.currentPlan?.subscription.package){
                    this.validationError = {
                        error: true,
                        message: this.$t('custom-keyword-count-error'),
                    }
                } else if(this.validationError.error) {
                    this.validationError = {
                        error: false,
                        message: '',
                    }
                }
            },
        }
    };
</script>

<style lang="scss">
    @import '~sass/modal';
    .custom-modal.custom-subscription-modal {
        position: relative;
        padding: 16px;
        font-family: Roboto;
        .close-btn {
            position: absolute;
            top: 8px;
            right: 8px;
            width: 16px;
            height: 16px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            font-size: 10px;
            font-weight: 900;
            color: kw-color(kw-grey, 3);
        }
        h3.title {
            font-size: 18px;
            font-weight: 500;
            margin: 0;
        }
        .description {
            margin: 16px 0;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-align: left;
            b {
                font-weight: 700;
            }
        }
        .input-wrapper {
            margin-bottom: 16px;
            p {
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
                margin-bottom: 4px;
            }
            .kw__inputfield {
                .kw__input {
                    max-width: 222px;
                    height: 40px;
                }

                &.has-error {
                    .error-message {
                        font-size: 8px;
                    }
                }
            }
        }
        .new-pricing {
            .title {
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                margin-bottom: 8px;
            }
            p {
                margin: 0;
                font-size: 12px;
            }
            margin-bottom: 24px;
        }
        .submit-block {
            width: 100%;
            button {
                width: 100%;
                height: 36px;
                margin-bottom: 8px;
            }
            .hint {
                font-size: 10px;
                font-style: italic;
                font-weight: 400;
                line-height: 16px;
            }
        }
        .downgrade-hint {
            font-size: 12px;
            line-height: 16px;
            b {
                font-weight: 700;
            }
        }
        .new-package-desc {
            &>span {
                text-transform: capitalize;
            }
        }
    }
</style>
