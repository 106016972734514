<template>
    <div
        class="settings_plan"
    >
        <div
            v-if="getSettingIsFetching"
            class="loader"
        >
            {{ $t('loading') }}
        </div>
        <div
            v-else
            class="settins_plan_container"
        >
            <div class="custom_row">
                <div class="settings_plan_block">
                    <div class="settings_plan_block_inner">
                        <billing />
                    </div>
                </div>
                <div class="settings_plan_block settings_plan_update_card">
                    <div class="settings_plan_block_inner">
                        <update-card />
                    </div>
                </div>
                <div class="settings_plan_block settings_plan_invoice">
                    <div class="settings_plan_block_inner">
                        <invoices :openBillingsDetailsModal="openBillingsDetailsModal" />
                    </div>
                </div>
            </div>
            <div
                v-if="getIsSubscriptionActive"
                class="cancel_subscription_button"
            >
              <p @click="openCancelSubscriptionModal"> {{ $t('cancel-your-subscription') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex';
  import Invoices from './planComponents/Invoices.vue';
  import Billing from './planComponents/Billing';
  import UpdateCard from './planComponents/UpdateCard';
  import CancelSubscriptionModal from '@/components/modals/CancelSubscriptionModal';
  import CustomSubscriptionModal from '@/components/modals/CustomSubscriptionModal';
  import { beforeModalOpen, beforeModalClose } from '@/helpers/modalHelpers';

  export default {
    name: 'AccountPlan',
    components: {
      'billing': Billing,
      'update-card': UpdateCard,
      'invoices': Invoices,
    },
    data () {
      return {
        cancelPlanLoading: false,
      };
    },
    computed: {
      ...mapGetters([
        'getSettingIsFetching',
        'getCurrentPlan',
      ]),

      getIsSubscriptionActive () {
        const {
            subscription,
            status,
        } = this.getCurrentPlan;
        return !subscription.cancel_at && !subscription.isExpired && status != 'error';
      },
      openBillingsDetailsModal() {
        return this.$route.params.openBillingsDetailsModal == true;
      },
    },

    mounted() {
      if(this.$route.query?.cancel === 'true') {
        this.$nextTick(() => {
          this.openCancelSubscriptionModal();
        })
      }
      if(this.$route.query?.custom_subscription === 'true') {
        this.$nextTick(() => {
          this.openCustomSubscriptionModal();
        })
      }
    },
    methods: {
      ...mapActions([
        'cancelSubscription',
        'fetchSettings'
      ]),
      async loadPlan () {
        await this.fetchSettings();
        this.checkLoadedPlan();
      },
      checkLoadedPlan () {
        if (!this.currentPlanStatusValid) {
          this.$toastr.e(this.$t('billing-load-plan-error'));
        }
      },
      openCancelSubscriptionModal () {
        const handleConfirmClick = this.tryToCancelSubscription;
        const loading = this.cancelPlanLoading;
        this.$modal.show(
          CancelSubscriptionModal,
          {
            handleConfirmClick,
            loading
          },
          {
            height: 'auto',
            pivotX: 0,
            name: 'center-modal-popup',
            width: 480,
            classes: 'v--modal center-modal-popup modal-for-mobile',
            clickToClose: true,
          },
          {
            'before-open': beforeModalOpen,
            'before-close': beforeModalClose
          },
        );
      },
      currentPlanStatusValid () {
        const defaultStatus = 'na';
        const {status = defaultStatus, subscription} = this.getCurrentPlan;

        if (subscription && subscription.active === 2) {
            return true;
        }

        // comming from legacy payment systems
        if (subscription && subscription.active === 1 && !subscription.scripe_active) {
            return true;
        }

        return status !== defaultStatus;
      },
      async tryToCancelSubscription() {
        this.cancelPlanLoading = true;
        await this.cancelSubscription();
        await this.loadPlan();
        this.cancelPlanLoading = false;
      },
      openCustomSubscriptionModal () {
        this.$modal.show(
          CustomSubscriptionModal,
          {
            currentPlan: this.getCurrentPlan
          },
          {
            height: 'auto',
            pivotX: 0,
            name: 'center-modal-popup',
            width: 436,
            classes: 'v--modal center-modal-popup modal-for-mobile',
            clickToClose: false,
          },
          {
            'before-open': beforeModalOpen,
            'before-close': beforeModalClose
          },
        );
      },
    }
  }
</script>

<style lang="scss">
  .settings_plan {
    max-width: 940px;

    .custom_row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;
    }

    .settings_plan_block {
      padding: 10px;
      max-width: 320px;
      min-width: 320px;

      @media screen and (max-width: 991px) {
        width: 100%;
        padding: 10px 10px 30px 10px;
        &:last-child {
          padding-bottom: 0;
        }
      }
    }

    .settings_plan_block_inner {
      position: relative;
      border: 1px solid #DEE5EC;
      border-radius: 4px;
      padding-top: 43px;
      display: block;
      min-height: 347px;

      .block_icon {
        position: absolute;
        width: 46px;
        height: 46px;
        top: -23px;
        left: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        span {
          &.kw-credit-card {
            font-size: 20px;
            font-weight: 700;
          }
        }

        &.plan-package {
          color: kw-color(kw-green, 5);
          background-color: #DFF5DE;

          &.warning {
            background-color: #FFF6E0;
            color: kw-color(kw-yellow, 7);
          }

          &.error {
            background-color: #FFECEA;
            color: kw-color(kw-red, 1);
          }
        }

        &.payment_method {
          color: kw-color(kw-blue, 1);
          background-color: #C0D6FA;
        }

        &.account_invoice {
          color: kw-color(kw-blue, 1);
          background-color: #C0D6FA;
        }
      }

      &.settings_plan_update_card {
        padding-top: 30px;
      }

      &.settings_plan_invoice {
        padding-top: 30px;
        margin-right: 0;
      }
    }

  }

  .settings_plan_title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: kw-color(kw-black, 1);
    margin-bottom: 8px;
  }

  .cancel_subscription_button {
    text-align: right;

    p {
      display: inline-block;
      color: kw-color(kw-red, 1);
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      text-transform: capitalize;
      cursor: pointer;

      &:hover {
        color: kw-color(kw-red, 1);
      }
    }
  }
</style>
