<template>
    <div
        ref="globalSearch"
        :class="`global-search-input-wrap ${classes} ${!isShown ? 'hide-bar' : ''}`"
        data-cy="global-search"
    >   
        <span @click.prevent="searchHandler" class="search-btn">
            <span
                class="kw kw-search"
            />
        </span>
        <form @submit.prevent="searchHandler">
            <vue-simple-suggest
                ref="suggestComponent"
                v-model="globalSearchQuery"
                :filter-by-query="true"
                :list="getSearchSuggestions"
                :min-length="0"
                :styles="autocompleteStyles"
            >
            <div class="input-box">
                <input
                    ref="search"
                    :class="inputSearchClasses"
                    :placeholder="$t('quick-actions-dots')"
                    type="text"
                    spellcheck="false"
                    :value="globalSearchQuery"
                    @blur="addKeyListener"
                >
            </div>
                <template
                    slot="misc-item-above"
                    slot-scope="{ suggestions }"
                >
                    <!-- Sub-template if have any suggestions -->
                    <template v-if="suggestions.length > 0">
                        <div class="suggestions-title">
                            <span>{{ $t('suggested-filters') }}</span>
                        </div>
                    </template>
                </template>


                <template
                    slot="misc-item-above"
                    slot-scope="{ suggestions }"
                >
                    <!-- Sub-template if have any suggestions -->
                    <template v-if="suggestions.length > 0">
                        <div class="suggestions-title">
                            <span>{{ $t('suggested-filters') }}</span>
                        </div>
                    </template>
                </template>

                <div
                    :title="suggestion.placeholder"
                    slot="suggestion-item"
                    slot-scope="{ suggestion }"
                    class="filters-suggestions"
                >
                    <div>
                        <span class="filter">{{ suggestion.title }}</span>
                        <span class="description">{{ suggestion.description }}</span>
                    </div>
                </div>
            </vue-simple-suggest>
        </form>
        <global-events
            target="window"
            @click.capture="windowClick"
        />
    </div>
</template>

<script>
    import { Events, EventBus } from '@/events';
    import { mapActions, mapGetters } from 'vuex';
    import VueSimpleSuggest from 'vue-simple-suggest';

    export default {
        name: 'GlobalSearchbar',
        components: {
            'vue-simple-suggest': VueSimpleSuggest,
        },
        props: {
            classes: {
                type: String,
                required: false,
                default: '',
            },
            onBlur: {
                type: Function,
                required: false,
                default: () => {},
            },
        },
        data () {
            return {
                globalSearchQuery: '',
                showSearchTip: false,
                isShown: false
            };
        },
        computed: {
            ...mapGetters([
                'isLeftSidebarOpen',
            ]),
            inputSearchClasses () {
                return {
                    'top-bar__input-global-search': true,
                };
            },
            autocompleteStyles() {
                return {
                    vueSimpleSuggest: 'custom-simple-suggest',
                    suggestions: 'custom-simple-suggest__suggestions',
                    suggestItem: 'custom-simple-suggest__item',
                }
            },
            searchTipLeftPosition() {
                const min = 70;
                const max = 350;
                return Math.min(Math.max(this.globalSearchQuery.length * 8 + 10, min), max);
            }
        },
        watch: {
            $route (to, from) {
                if (from.path === '/search' && to.path !== '/search') {
                    this.globalSearchQuery = '';
                    this.resetGlobalSearchQuery();
                }
            },
        },
        mounted() {
            if (this.$route.query && this.$route.query.query) {
                this.globalSearchQuery = this.$route.query.query;
                this.isShown = true
            }

            this.addKeyListener();
        },
        beforeDestroy() {
            this.removeKeyListener();
        },
        methods: {
            ...mapActions([
                'resetGlobalSearchQuery'
            ]),
            windowClick (e) {
                if (!this.$refs.globalSearch.contains(e.target)) {
                    this.onBlur();
                }
            },
            searchHandler () {
                if (this.globalSearchQuery) {

                    this.$refs.suggestComponent?.hideList();
                    EventBus.emit(Events.USED_GLOBAL_SEARCH, { query: this.globalSearchQuery });
                    this.$router.push({
                        name: 'searchResults',
                        query: { query: this.globalSearchQuery },
                    });
                } else {
                    this.isShown = !this.isShown
                }
            },
            keyListener(e) {
                if (e.key === '/' && e.target.tagName.toUpperCase() === 'BODY' ) {
                    e.preventDefault();
                    if (this.$refs && this.$refs.search) {
                        this.isShown = true;
                        this.$refs.search.focus();
                        this.removeKeyListener();
                    }
                }
            },
            addKeyListener() {
                document.addEventListener('keydown', this.keyListener);
            },
            removeKeyListener() {
                document.removeEventListener('keydown', this.keyListener);
            },
            getSearchSuggestions() {
                return [
                    {
                        id: 'kw',
                        title: 'kw:',
                        description: this.$t('search-by-keyword'),
                    },
                    {
                        id: 'url',
                        title: 'url:',
                        description: this.$t('search-by-domain-or-url')
                    },
                    {
                        id: 'rankingurl',
                        title: 'rankingurl:',
                        description: this.$t('search-by-rankingurl')
                    },
                    {
                        id: 'rankingMore',
                        title: 'ranking: > ',
                        description: this.$t('search-by-ranking-value')
                    },
                    {
                        id: 'rankingLess',
                        title: 'ranking: < ',
                        description: this.$t('search-by-ranking-value')
                    },
                    {
                        id: 'rankingEqual',
                        title: 'ranking: = ',
                        description: this.$t('search-by-ranking-value')
                    },
                    {
                        id: 'tags',
                        title: 'tags:',
                        description: this.$t('search-by-tags')
                    },
                    {
                        id: 'favorite',
                        title: 'favorited:',
                        description: this.$t('search-by-favorite')
                    },
                    {
                        id: 'searchEngine',
                        title: 'searchengine:',
                        description: this.$t('search-by-searchengine'),
                        placeholder: this.$t('search-by-searchengine-placeholder')
                    },
                ];
            },
        }
    };

</script>

<style lang="scss">
  @import '~sass/partials/custom-simple-suggest';

  .custom-simple-suggest__suggestions {
    z-index: 102;

    .filters-suggestions {
      color: #697386;

      .filter {
        opacity: .8;
        background-color: #e3e8ee;
        color: #7B7E86;
        padding: 5px;
        font-size: .8em;
        margin-right: 5px;
        border-radius: 3px;
      }
    }

    .custom-simple-suggest__item:nth-child(odd) {
      background-color: kw-color(kw-white, 1);
    }

    .hover {
      background-color: kw-color(kw-blue, 1) !important;

      .description {
        color: kw-color(kw-white, 1);
      }
    }

    .suggestions-title {
      padding: 8px 15px;
      text-transform: uppercase;
      background-color: kw-color(kw-white, 1);
      color: #697386
    }

    .see-all-results-title {
      padding: 8px 15px;
      background-color: kw-color(kw-white, 1);
      color: kw-color(kw-white, 1);

      span {
        cursor: pointer;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .global-search-input-wrap {
    border: 2px solid #88C1F9;
    position: relative;
    border-radius: 4px;
    display: flex !important;
    max-width: 100%;
    flex: 1;
    justify-content: flex-end;
    margin-left: 10px;
    
    @media (min-width: 960px) {
        display: flex !important;
        max-width: 50%;
        flex: unset;
        justify-content: unset;
    }

    form {
        max-width: calc(100% - 32px);
        width: 100%;
    }

    .input-box {
        transition: all 0.3s;
        overflow: hidden;
        max-width: 100%;
        width: 600px;
        @media screen and (max-width: 960px) {
            width: 100%;
            transition: none;
        }
    }

    &.hide-bar{
        border: 1px solid transparent;
        .input-box {
            width: 0;
        }
        form {
            width: unset;
        }
    }

    .search-tip {
      position: absolute;
      top: 8px;
      width: 20px;
      height: 20px;
      opacity: .8;
      background-color: #e3e8ee;
      color: #7B7E86;
      text-align: center;
      border-radius: 2px;
    }

    .search-btn {
        position: relative;
        min-width: 32px;
        width: 32px;
        height: 32px;
        z-index: 1;
        background: kw-color(kw-white, 1);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #667085;
        cursor: pointer;

        &:hover {
            background: #F7F8FA;
        }

        .kw-search {
            position: relative;
            min-width: 16px;
            width: 16px;
            height: 16px;
            font-size: 14px;
            font-weight: 700;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
  }

  .top-bar__input-global-search {
    appearance: none;
    background: transparent;
    border-radius: 4px;
    border: none;
    display: flex;
    height: 32px;
    margin-right: 10px;
    padding: 0 32px 0 8px;
    transition: all .25s ease;
    z-index: 1;
    color: kw-color(kw-black, 1);
    cursor: text;
    outline: transparent;
    width: 100%;

    &::placeholder {
      color: transparent;
    }

    @media (max-width: 960px) {
      padding-left: 11px;
    }

    &::placeholder {
      color: #909399;
    }

  }

  @media screen and (max-width: 959px) {
    .keyword-search-input-wrap {
      margin-right: 8px;
      flex-grow: 1;

      .kw-search:before {
        right: 10px;
        bottom: -9px;
        top: unset;
        transform: unset;
      }
    }
    .top-bar__input-global-search {
      margin-right: unset;
      padding: 0 10px !important;
      width: 100% !important;

      &::placeholder {
        color: kw-color(kw-grey, 1) !important;
      }
    }
  }
</style>
