<template>
    <input v-model="value" @input="preventNonNumericInput" />
  </template>
  
  <script>
  export default {
    name: 'KwNumericInputField',
    data() {
      return {
        value: ''
      };
    },
    methods: {
      preventNonNumericInput(event) {
        const value = event.target.value;
        // Replace any non-digit characters
        const numericValue = value.replace(/\D/g, '');
  
        // Update the input value if it's different
        if (numericValue !== value) {
          event.target.value = numericValue;
        }
  
        // Update the Vue model value
        this.value = numericValue;
      }
    }
  };
  </script>
  