export default {
    'unexpected-server-error': 'Unexpected server error, please contact support',
    'unexpected-error': 'Unexpected error. Please try again.',

    'projects-fetch-error': 'Error during active projects fetch',
    'project-add-error': 'Error during project creation',
    'project-update-error': 'Error during project update',
    'project-delete-error': 'Error during project deletion',
    'project-archive-error': 'Error during project archiving',
    'project-viewkey-fetch-error': 'Error during project fetch',

    'fetch-settings-error': 'Error during fetch settings',
    'invalid-old-password': 'Invalid old password',
    'invalid-current-password': 'Invalid current password',
    'settings-update-error': 'Error during settings update',
    'update-card-error': 'Error during card updating',

    'notes-fetch-error': 'Error during notes fetch',
    'notes-add-error': 'Error during note creation',
    'notes-delete-error': 'Error during note deletion',
    'notes-update-error': 'Error during note update',

    'check-email-error': 'Error during check email',
    'login-error-msg': 'Incorrect email and password combination',
    'no-such-user': 'User not found in the system, please try again',
    'password-link-expired': 'Your password recovery link has expired',
    'refresh-password-error': 'Unexpected refresh error, please contact support',

    'alerts-count-error': 'Error during alerts count fetch',
    'alerts-fetch-error': 'Error during alerts fetch',

    'add-addon-error': 'Error during addon creation',
    'delete-addon-error': 'Error during addon deletion',
    'existed-addon-error': 'Such User already existed',
    'fetch-addons-error': 'Error during addons fetch',
    'update-addon-error': 'Error during addon update',

    'invoice-fetch-error-msg': 'Error on fetch invoices',
    'invoice-options-fetch-error-msg': 'Error on fetch invoice info',
    'invoice-options-save-error-msg': 'Error of save invoice info',

    'billing-subscription-resume-error': 'Error on renew subscription',
    'billing-update-card-error-msg': 'Error on update card',
    'update-plan-error': 'There was a problem updating your plan. Please contact the support team',

    'fetch-countries-error': 'Error during countries fetch',
    'fetch-states-error': 'Error during states fetch',

    'keyword-delete-error': 'Error on delete keyword(s)',
    'report-delete-error': 'Error during report deletion',
    'report-add-error': 'Error during report creation',
    'report-edit-error': 'Error during report update',
    'report-fetch-error': 'Error during reports loading',
    'whitelabel-domains-fetch-error': 'Error during whitelabel domains loading',
    'duplicate-detected': 'Duplicate detected. Changes not saved.',

    'plan-limit-exceeded': 'You only have {left} available keywords, but you are trying to add {count} keywords! Please delete some keywords or upgrade your subscription',

    'csv-import-error': 'Your import failed. Please check the .csv and try again.'
};
